import WorldIcon from '@/components/ui/atoms/icons/world-icon'
import { useStoreLanguages } from '@bluheadless/ui-logic/src/hooks/store-languages/useStoreLanguages'
import { useWorldStores } from '@bluheadless/ui-logic/src/hooks/world-stores/useWorldStores'
import { useCurrency } from '@bluheadless/ui-logic/src/providers/currency'
import { useStore } from '@bluheadless/ui-logic/src/providers/store'
import { bool, func, string } from 'prop-types'
import FlagByCountryCode from '../../atoms/icon-flag-by-country-code'
import ArrowheadDownIcon from '../../atoms/icons/arrowhead-down-icon'
import {
	CountryCode,
	CurrencySymbol,
	Language,
	Separator,
	TriggerWrapper,
} from './change-country-language-trigger.styled'

const ChangeCountryLanguageTrigger = ({
	className,
	countryCode,
	customSymbol: customSymbolProp,
	showArrowDown,
	showSeparator,
	showFlag: _showFlag,
	showCountryCode: _showCountryCode,
	showCurrencySymbol: _showCurrencySymbol,
	showLanguage: _showLanguage,
	onClick,
	loading: _loading,
	showCountryFlag,
}) => {
	if (!_showFlag && !_showCountryCode && !_showCurrencySymbol && !_showLanguage) {
		console.warn(
			'[ChangeCountryLanguageTrigger] One of showFlag, showCountryCode, showCurrencySymbol or showLanguage must be true'
		)
	}

	const { symbol } = useCurrency()

	const currencySymbol = customSymbolProp || symbol

	const { code: currentStoreCode, name: currentLanguage } = useStore()
	const { languages, loading: loadingLanguages } = useStoreLanguages({ store: currentStoreCode })
	const { isSingleWebsite } = useWorldStores()
	const isSingleLanguage = languages?.length === 1
	const showFlag = (_showFlag ?? true) && !isSingleWebsite
	const showCountryCode = (_showCountryCode ?? true) && !isSingleWebsite
	// language should be visible only if there are multiple languages in a single website
	const showLanguage = _showLanguage && !showFlag && !showCountryCode && isSingleWebsite && !isSingleLanguage
	const showCurrencySymbol = (_showCurrencySymbol ?? true) && (showFlag || showCountryCode || showLanguage)

	const loading = _loading || loadingLanguages

	return showFlag || showCountryCode || showLanguage ? (
		<TriggerWrapper className={className} onClick={!loading ? onClick : undefined}>
			{!isSingleWebsite ? (
				showCountryFlag ? (
					<FlagByCountryCode countryCode={loading ? undefined : countryCode} />
				) : (
					<WorldIcon fontSize="small" sx={{ marginRight: '10px' }} />
				)
			) : null}
			{showSeparator && showFlag && (showCurrencySymbol || showCountryCode) && <Separator>|</Separator>}
			{showCountryCode && (
				<CountryCode addMargin={!showSeparator && showFlag}>{loading ? '--' : countryCode}</CountryCode>
			)}
			{showLanguage && (
				<Language addMargin variant="body1" component="span">
					{currentLanguage}
				</Language>
			)}
			{showCurrencySymbol && currencySymbol && (
				<CurrencySymbol>
					{(showFlag || showCountryCode) && <>&nbsp;/&nbsp;&nbsp;</>}
					{currencySymbol}
				</CurrencySymbol>
			)}
			{showArrowDown && <ArrowheadDownIcon fontSize="small" />}
		</TriggerWrapper>
	) : null
}

ChangeCountryLanguageTrigger.defaultProps = {
	showArrowDown: true,
	showSeparator: false,
	showFlag: true,
	showCountryCode: true,
	showCurrencySymbol: true,
	showLanguage: true,
}

ChangeCountryLanguageTrigger.propTypes = {
	countryCode: string,
	customSymbol: string,
	showArrowDown: bool,
	showSeparator: bool,
	showFlag: bool,
	showCountryCode: bool,
	showCurrencySymbol: bool,
	showLanguage: bool,
	onClick: func,
}

export default ChangeCountryLanguageTrigger
